<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 13:08:47
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-26 18:38:16
 * @FilePath: \beijing-peoples-art-museum\src\views\mobile\performance\components\CinemaCom.vue
 * @Description: 观剧指南
 *
-->
<template>
  <div class="info-card">
    <div class="title">
      <span>观剧指南</span>
    </div>
    <div class="body line-box">
      <div class="line" @click="handleDetail('')">
        <span>周边美食</span>
      </div>
      <div class="line" @click="handleDetail('desc')">
        <span>观众须知</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleDetail(tab) {
      const { href } = this.$router.resolve({
        path: "/culture/guide/index",
        query: {
          tab,
        },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.info-card {
  margin: 30/75rem;
  border-radius: 12/75rem;
  background: rgba(249, 104, 13, 0.1);
  padding: 0 30/75rem;
  position: relative;
  .title {
    padding-top: 30/75rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 34/75rem;
    font-weight: 800;
    color: #212122;
    .img {
      width: 20/75rem;
      height: 20/75rem;
      margin-right: 5/75rem;
    }
  }
}
.line-box {
  .line {
    height: 84/75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30/75rem;
    font-weight: 400;
    color: #212122;
    cursor: pointer;
    & + .line {
      border-top: 1/75rem solid rgba(247, 207, 152, 0.5);
    }
    &::after {
      content: "";
      width: 16/75rem;
      height: 16/75rem;
      background-image: url("~@/assets/img/performance/arrowright.png");
      background-size: 100%;
    }
  }
}
</style>
