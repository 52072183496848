<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 10:39:04
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-30 15:10:40
 * @FilePath: \beijing-peoples-art-museum\src\views\mobile\performance\components\OperaList.vue
 * @Description: 演出列表
 *
-->
<template>
  <div class="operalist">
    <div class="filter-row">
      <p>
        全部剧目(<span class="color-primary">{{ total }}</span
        >)
      </p>
      <div class="select-row">
        <div class="select-item">
          <el-select
            v-model="form.theatresId"
            popper-class="mobile-select"
            @change="handleSearch"
          >
            <el-option label="全部剧场" value="all" />
            <el-option
              v-for="option in theatres"
              :key="option.id"
              :label="option.value"
              :value="option.id"
            />
          </el-select>
        </div>
        <div class="select-item">
          <el-select
            v-model="form.performerId"
            popper-class="mobile-select"
            @change="handleSearch"
          >
            <el-option label="全部" value="all" />
            <el-option
              v-for="option in performer"
              :key="option.id"
              :label="option.value"
              :value="option.id"
            />
          </el-select>
        </div>
      </div>
    </div>
    <ul class="list">
      <li
        v-for="item in list"
        :key="item.id"
        class="list-item"
        @click="handleDetail(item)"
      >
        <div class="left">
          <c-image :src="item.image" />
          <span v-if="item.tag" class="tag">{{ item.tag?.split(",")[0] }}</span>
        </div>
        <div class="right">
          <div class="info">
            <p class="line-name overflow-ellipsis">
              {{ item.repertoireName }}
            </p>
            <p class="line-tag">
              <span class="tag">{{ item.performerName }}</span>
            </p>
            <p class="line-info">
              <c-image
                :src="require('@/assets/img/performance/reloadtime@2x.png')"
              />
              <span>上演日期：{{ item.showTime }}</span>
            </p>
            <p class="line-info">
              <c-image :src="require('@/assets/img/performance/bank@2x.png')" />
              <span class="overflow-ellipsis"
                >演出剧场：{{ item.theatresName }}</span
              >
            </p>

            <div class="line-info price-info">
              <c-image
                :src="require('@/assets/img/performance/creditcard@2x.png')"
              />
              <div class="line-price">
                <span>演出票价：</span>
                <p class="price overflow-ellipsis">{{ item.price || "-" }}</p>
              </div>
            </div>
          </div>
          <span class="btn-buy" @click.stop="handleBuy(item)">购票</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  getRepertoirePage,
  getRepertoirePageBetter,
} from "@/service/performance";
export default {
  props: {
    date: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        theatresId: "all",
        performerId: "all",
        status: 1,
      },
      pager: {
        pageNo: 1,
        pageSize: 20,
      },
      total: 0,
      list: [],
      timer: null,
      nowDate: dayjs().startOf("day").format("YYYY-MM-DD"),
    };
  },
  created() {
    this.$store.dispatch("getDict", ["theatres", "performer"]);
    this.getData2();
  },
  watch: {
    date() {
      this.handleSearch();
    },
  },
  computed: {
    theatres() {
      return this.$store.state.dictMap.theatres || [];
    },
    performer() {
      return this.$store.state.dictMap.performer || [];
    },
  },
  methods: {
    async getData() {
      try {
        const params = {
          ...this.form,
          performanceTime: this.nowDate,
          orderSign: 4,
          orderDirection: 0,
        };
        if (this.date) {
          params.performanceTime = this.date;
        }
        if (params.theatresId === "all") {
          delete params.theatresId;
        }
        if (params.performerId === "all") {
          delete params.performerId;
        }
        const res = await getRepertoirePage({
          ...this.pager,
          condition: params,
        });
        if (res.data) {
          let data = res.data.data || [];
          if (data.length < this.pager.pageSize) {
            this.hasNextPage = false;
          }
          if (this.pager.pageNo === 1) {
            this.list = data;
          } else {
            this.list = this.list.concat(data);
          }
          this.total = res.data.totalCount || 0;
        }
      } catch (e) {
        console.log(e);
        if (this.pager.pageNo === 1) {
          this.list = [];
        }
      }
    },
    async getData2() {
      try {
        const params = { ...this.form, performanceTime: this.nowDate };
        if (this.date) {
          params.performanceTime = this.date;
        }
        if (params.theatresId === "all") {
          delete params.theatresId;
        }
        if (params.performerId === "all") {
          delete params.performerId;
        }
        const res = await getRepertoirePageBetter({
          ...this.pager,
          condition: params,
        });
        if (res.data) {
          let data = res.data.data || [];
          if (data.length < this.pager.pageSize) {
            this.hasNextPage = false;
          }
          if (this.pager.pageNo === 1) {
            this.list = data;
          } else {
            this.list = this.list.concat(data);
          }
          this.total = res.data.totalCount || 0;
        }
      } catch (e) {
        console.log(e);
        if (this.pager.pageNo === 1) {
          this.list = [];
        }
      }
    },
    handleSearch() {
      this.hasNextPage = true;
      this.pager.pageNo = 1;
      this.getData();
    },
    handleMore() {
      clearTimeout(this.timer);
      if (!this.hasNextPage) return;
      this.timer = setTimeout(() => {
        this.pager.pageNo++;
        this.getData();
      }, 500);
    },
    handleBuy() {
      window.open(process.env.VUE_APP_TICKET_URL, "_blank");
    },
    // 查看详情
    handleDetail(item) {
      window.open(`#/performance/detail/${item.id}`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.performance-index-mobile .operalist {
  .filter-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40/75rem 30/75rem 30/75rem;
    > p {
      margin: 0;
      font-size: 36/75rem;
      font-weight: bold;
      color: #212122;
      line-height: 36/75rem;
    }
    .select-row {
      display: flex;
      align-items: center;
      justify-content: right;
      font-size: 16/75rem;
      font-weight: 400;
      color: #606266;
      line-height: 24/75rem;
      ::v-deep(.el-input__inner) {
        font-size: 20/75rem;
        color: #212122;
        width: 190/75rem;
        height: 30/75rem;
        line-height: 30/75rem;
        padding: 0 10/75rem;
        background: transparent;
        border-radius: 2/75rem;
        border: 1/75rem solid #d8dade;
        margin-left: 12/75rem;
      }
      ::v-deep(.el-input__icon) {
        line-height: 30/75rem;
        color: #212122;
        font-weight: bold;
      }
      .select-item + .select-item {
        margin-left: 20/75rem;
      }
    }
  }
  .list {
    padding: 0 0 40/75rem;
    margin: 0 0 40/75rem;
    list-style: none;
    border-bottom: 1/75rem solid #e4e6eb;
    .list-item {
      margin: 0 30/75rem;
      height: 312/75rem;
      box-sizing: border-box;
      position: relative;
      display: flex;
      background-color: #ffffff;
      & + .list-item {
        margin-top: 30/75rem;
      }
      .left {
        flex-shrink: 0;
        width: 212/75rem;
        height: 312/75rem;
        border-radius: 12/75rem;
        z-index: 2;
        .img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 12/75rem;
        }
        .tag {
          position: absolute;
          left: 4/75rem;
          top: 4/75rem;
          height: 20/75rem;
          background: #bf805f;
          border-radius: 2/75rem;
          line-height: 20/75rem;
          font-size: 12/75rem;
          color: #ffffff;
          padding: 0 8/75rem;
        }
      }
      .right {
        flex: 1;
        margin-left: 30/75rem;
        height: 100%;
        // width: calc(100% - 10 / 75rem);
        .info {
          height: 100%;
          padding: 24/75rem 0 30/75rem;
          box-sizing: border-box;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .line {
            &-name {
              font-size: 34/75rem;
              font-weight: 800;
              color: #212122;
              max-width: 364/75rem;
            }
            &-tag {
              margin: 12/75rem 0 17/75rem 0;
              display: flex;
              .tag {
                font-size: 20/75rem;
                font-weight: 400;
                color: #212122;
                background: #f8d39f;
                border-radius: 2/75rem;
                height: 28/75rem;
                line-height: 28/75rem;
                padding: 0 8/75rem;
                margin-right: 12/75rem;
              }
            }
            &-info {
              margin-top: 20/75rem;
              display: flex;
              align-items: center;
              font-size: 24/75rem;
              font-weight: 400;
              color: #909399;
              .img {
                width: 24/75rem;
                height: 24/75rem;
                margin-right: 12/75rem;
              }
              .price {
                color: var(--primary-color);
                font-weight: bold;
              }
            }
          }
          .price-info {
            align-items: flex-start;
            line-height: 26/75rem;
            .img {
              flex-shrink: 0;
            }
            span {
              flex-shrink: 0;
            }
            .line-price {
              display: flex;
              font-size: 24/75rem;
              span {
                flex-shrink: 0;
              }
              .price {
                color: var(--primary-color);
                font-weight: bold;
              }
            }
          }
        }
        .btn-buy {
          position: absolute;
          right: 0;
          top: 26/75rem;
          color: var(--primary-color);
          cursor: pointer;
          font-size: 30/75rem;
        }
      }
    }
  }
}
</style>

<style lang="less">
.mobile-select {
  .el-select-dropdown__item {
    font-size: 20/75rem;
    line-height: 26/75rem;
    height: 26/75rem;
  }
}
</style>
