<!--
 * @Author: zhongxq
 * @Date: 2023-10-26 17:29:16
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-30 15:11:17
 * @FilePath: \beijing-peoples-art-museum\src\views\mobile\performance\index.vue
 * @Description:
 *
-->
<template>
  <div
    class="performance-index-mobile"
    v-infinite-scroll="handleMore"
    :infinite-scroll-distance="100"
  >
    <div class="container">
      <section class="panel-left">
        <div>
          <swiper
            v-if="bannerList.length"
            :options="swiperOptionTop"
            class="banner-carousel"
            ref="swiperTop"
          >
            <swiper-slide v-for="(item, index) in bannerList" :key="index">
              <c-image
                :src="item.image"
                style="width: 100%; height: 100%"
                @click="handleClick(item)"
              />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <!-- 演出列表 -->
        <OperaList ref="listRef" :date="date" />
      </section>
      <aside class="panel-right">
        <TelCom />
        <CinemaCom />
      </aside>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { getSysHomeConfigList } from "@/service/system";
import OperaList from "./components/OperaList.vue";
import TelCom from "./components/TelCom.vue";
import CinemaCom from "./components/CinemaCom.vue";

export default {
  components: { OperaList, TelCom, CinemaCom, swiper, swiperSlide },
  data() {
    return {
      bannerList: [],
      date: "",
      swiperOptionTop: {
        spaceBetween: 0,
        autoplay: {
          //自动轮播
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
      },
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    changeDate(date) {
      this.date = date;
    },
    handleMore() {
      this.$refs.listRef?.handleMore();
    },
    async getBanner() {
      const res = await getSysHomeConfigList({
        level1: "jumuBanner",
        isShow: 1,
      });
      this.bannerList = res.data || [];
    },
    handleClick(item) {
      item.targetId &&
        this.$router.push({
          path: `/performance/detail/${item.targetId}`,
        });
    },
  },
};
</script>

<style lang="less">
.performance-index-mobile {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  p {
    margin: 0;
  }
  .container {
    width: 100%;
  }
  .banner-carousel {
    height: 318/75rem;
    .swiper-pagination {
      .swiper-pagination-bullet {
        height: 10/75rem;
        width: 10/75rem;
        border-radius: 10/75rem;
        background-color: #ffffff;
        opacity: 0.6;
        &.swiper-pagination-bullet-active {
          opacity: 1;
        }
      }
    }
  }
  .list-card + .list-card {
    margin-top: 30/75rem;
  }
}
</style>
